<template>
	<div class="login">
		<div class="logo text-center">
			<a href="https://www.dentroeduca.com.br/" target="_blank"
				><img
					width="127"
					:src="require('@/assets/primary-1.svg')"
					alt="Dentro Educa"
					class
			/></a>
		</div>

		<h3>Área exclusiva para Alunos</h3>
		<h5>Faça seu login para acessar a área do aluno.</h5>

		<form @submit.prevent="doLogin" class="va-row">
			<div class="flex xs12">
				<div class="input-group">
					<input-normal
						type="text"
						label="Digite o código do aluno"
						id="username"
						name="username"
						ref="username"
						v-validate="'required'"
						:disabled="false"
						:data-vv-as="'usuário'"
						:value="login.username"
						:error="errors.first('username')"
						v-model="login.username"
						@input="updateAndVerifyLogin"
					/>
				</div>
			</div>

			<div class="flex xs12">
				<div class="input-group" v-if="showSchools">
					<select-normal
						:items="schoolList"
						:value="''"
						label="Selecione sua escola"
						name="typeName"
						:error="errors.first('escola')"
						@change="onChange($event)"
					/>
				</div>

				<div class="error text-center">{{ errors.first("error") }}</div>
			</div>

			<div class="d-flex align--center justify--space-between flex xs12">
				<button
					class="btn btn-primary blue-light"
					type="submit"
					:disabled="sendActive"
				>
					Entrar
				</button>
			</div>
		</form>
	</div>
</template>

<script>
	import { mask } from "vue-the-mask";
	const axios = require("axios");

	export default {
		name: "login-user",

		data() {
			return {
				showSchools: false,
				schoolList: [],

				login: {
					username: null,
					user_temporary_id: null,
					school_id: null,
				},

				sendActive: true,
			};
		},

		mounted() {
			const self = this;

			self.$nextTick(() => {
				this.$refs.username.$refs.input.focus();
			});
		},

		methods: {
			doLogin() {
				const self = this;
				this.errors.clear();

				this.$validator.validateAll().then((result) => {
					if (result) {
						//self.login.username = self.login.username + "@dentrodaescola.com.br"

						axios
							.post(
								process.env.VUE_APP_ROOT_API + "/students/step_2",
								self.login
							)
							.then(function (response) {
								let studentCredential = {
									token: response.data.data.token,
									user: response.data.data.user,
									main_product: response.data.data.main_product,
									multiple_products: response.data.data.multiple_products,
									dedication: response.data.data.dedication,
									uuid: response.data.data.uuid,
									settings: response.data.data.settings,
									gradeId: response.data.data.grade.id,
									isTeacher: false,
									isUpdate: false,
								};

								if (response.data.data.user.products.length > 1) {
									studentCredential.isUpdate = true;
									studentCredential.main_product =
										response.data.data.user.products[0].product;
								}

								if (studentCredential.uuid) {
									studentCredential.old_uuid = studentCredential.uuid;
								}

								if (response.data.data.user.name) {
									studentCredential.user.name = response.data.data.user.name;
								}

								if (response.data.data.custom_name) {
									studentCredential.user.custom_name =
										response.data.data.custom_name;
								} else {
									studentCredential.user.custom_name =
										response.data.data.user.name.split(" ")[0];
								}

								studentCredential.main_product.isEditing = true;

								if (parseInt(response.data.data.grade.status) > 20) {
									studentCredential.main_product.have_tools = false;
								}

								self.$store.commit(
									"SAVE_STUDENT_CREDENTIALS",
									studentCredential
								);

								// let studentCredential = {
								// 	token: response.data.data.token,
								// 	user: response.data.data.user,
								// 	main_product: response.data.data.main_product,
								// 	multiple_products: response.data.data.multiple_products,
								// 	dedication: response.data.data.dedication,
								// 	uuid: response.data.data.uuid,
								// 	settings: response.data.data.settings,
								// 	gradeId: response.data.data.grade.id,
								// 	isTeacher: false,
								// };

								// if (response.data.data.user.name) {
								// 	studentCredential.user.custom_name =
								// 		response.data.data.user.name.split(" ")[0];
								// } else if (response.data.data.main_product.name) {
								// 	studentCredential.user.custom_name =
								// 		response.data.data.main_product.name;
								// } else if (response.data.data.user.custom_name) {
								// 	studentCredential.user.custom_name =
								// 		response.data.data.user.custom_name;
								// }

								// studentCredential.main_product.isEditing = true;

								// studentCredential.main_product.releasePrintEnabled =
								// 	self.gradeInfo.release_print_enabled;

								self.$store.commit(
									"SAVE_STUDENT_CREDENTIALS",
									studentCredential
								);

								if (response.data.data.isComplete) {
									let params = {
										builderCharacterId: response.data.data.uuid,
										builderProductId:
											response.data.data.main_product.builder_id,
										isStudent: false,
										grade: response.data.data.grade,
										isComplete: response.data.data.isComplete,
									};

									let subscription = {
										loadAvatar: true,

										product: {
											builderCharacterId: studentCredential.uuid,
											builderProductId:
												studentCredential.main_product.builder_id,
											characterSlug: "1",
											characterType:
												studentCredential.main_product.gender[self.gender],
											characterId: studentCredential.main_product.character_id,
										},

										subscription: {
											characterBirthdate: studentCredential.user.birthdate,
											name: self.name,
											id: "1",
											subscriptionStackId: "1",
										},
									};

									self.$store.commit("SAVE_STUDENT_SUBCRIPTION", subscription);
									self.$router.push({
										name: "preview",
										params: { isComplete: false, product: params },
									});
								} else {
									self.$router.push({ name: "student-step-1" });
								}
							})
							.catch(function (error) {
								// console.log(error)

								let fieldError = {
									field: "error",
									msg: error.response.data.message,
									rule: "error", // optional
								};
								self.$validator.errors.add(fieldError);
							});
					}
				});
			},

			created: function () {
				const self = this;

				axios
					.post(process.env.VUE_APP_ROOT_API + "/students/login", self.login)
					.then((res) => {
						self.schoolList = self.createListOfSchools(res.data.data.schools);
						self.login.user_temporary_id = res.data.data.user_temporary_id;
						self.showSchools = true;
					})
					.catch((error) => {
						this.openAddressField = true;
						self.showSchools = false;

						let fieldError = {
							field: "error",
							msg: error.response.data.message,
							rule: "error", // optional
						};
						self.$validator.errors.add(fieldError);
					});
				//}
			},

			createListOfSchools(schoolList) {
				let list = [];

				schoolList.forEach((element) => {
					var item = { key: element.id, value: element.name };
					list.push(item);
				});

				return list;
			},

			onChange(event) {
				this.login.school_id = event.target.value;
				this.sendActive = false;
				//this.address = event.target.value
			},

			updateAndVerifyLogin({ e, target }) {
				this.login.username = target.value;
				if (this.login.username.length > 5) {
					this.created();
				}

				if (this.login.username.length > 10) {
					this.created();
				}
			},
		},
	};
</script>

<style lang="scss">
	.select-group {
		width: 100%;
	}
	.select {
		max-width: 100%;
		box-sizing: border-box;
		-ms-touch-action: manipulation;
		-webkit-appearance: none;
		touch-action: manipulation;
		margin-bottom: 0;
		z-index: 1;
		transition: all 150ms ease;
		padding: 4px 4px;
		display: block;
		width: 100%;
		background-color: transparent;
		height: 65px;
		border-radius: 5px;
		border: solid 1px #dfdae4;
		font-family: Roboto;
		font-size: 16px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: normal;
		color: #000000;
		padding-left: 20px;
		width: 100%;
	}
	.recover-link {
		position: absolute;
		right: 20px;
		top: 35px;
		width: 150px;

		.btn {
			width: 100%;
		}
	}
	.login {
		font-family: "Nunito";
		width: 35rem;
		text-align: center;

		@include media-breakpoint-down(md) {
			width: 100%;
			padding-right: 2rem;
			padding-left: 2rem;
			.down-container {
				display: none;
			}
		}

		h3 {
			text-align: center;
			font-size: 24px;
			font-weight: 900;
			font-style: normal;
			font-stretch: normal;
			line-height: 1.17;
			letter-spacing: 1.33px;
			text-align: center;
			color: #68596e;
			font-weight: 800;
			margin-bottom: 10px;
		}

		h5 {
			font-size: 18px;
			font-weight: normal;
			font-style: normal;
			font-stretch: normal;
			line-height: normal;
			letter-spacing: normal;
			color: #afa2ba;
			margin-bottom: 30px;
		}

		.btn-primary {
			width: 100%;
			//font-family: "Nunito";
			font-size: 14px;
			font-weight: 900;
			font-style: normal;
			font-stretch: normal;
			line-height: 4;
			letter-spacing: 0.8px;
			text-align: center;
			color: #ffffff;
			border-radius: 40px;
			text-transform: uppercase;
			margin-top: 30px;
		}
		.down-container {
			margin-top: 3.125rem;
		}
		.blue-light {
			background-image: none !important;
			background-color: #16d3db !important;
			&:hover {
				background-color: #17b8bc !important;
				transition: 0.5s;
			}
		}
	}
</style>
